@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: Gilroy-Regular;
  src: url(./gilroy/Gilroy-Regular.ttf);
}

@font-face {
  font-family: Gilroy-Bold;
  src: url(./gilroy/Gilroy-Bold.ttf);
}

@font-face {
  font-family: Gilroy-SemiBold;
  src: url(./gilroy/Gilroy-SemiBold.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.Gilroy-Regular {
  font-family: Gilroy-Regular
}

.Gilroy-Bold {
  font-family: Gilroy-Bold
}

.Gilroy-SemiBold {
  font-family: Gilroy-SemiBold
}

.Poppins {
  font-family: "Poppins"
}

.tortoise {
  background: #0A9A73;
}

.submit-button {
  width: 70%;
  background: #0A9A73;
  border: 0px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



@tailwind base;
@tailwind components;
@tailwind utilities;