.cart-header {
  font-family: "Poppins";
  font-weight: 300;
  letter-spacing: 0.02em;
  color: #1a1a1a;
  margin-bottom: 2%;
}

.underline {
  width: 100%;
  height: 0px;
  border-bottom: 0.01px solid #cbcbcb;
  margin-bottom: 10%;
}

.cart-container {
  font-family: "Poppins";
  max-width: 98%;
  max-height: 100vh;
}




.cart-item {
  margin-bottom: 5%;
}

.cart-item h6 {
  font-size: 15px;
  letter-spacing: 0.02em;
  color: #1a1a1a;
  font-weight: 550;
}

.cart-item img {
  margin-left: auto;
  cursor: pointer;
}

.cart-container button {
  margin-left: 5%;
  background: #0a9a73;
  border-radius: 8px;
  color: white;
  margin-top: 4%;
  border: none;
  width: 85%;
}

.cart-container button:hover {
  background: #005F45;
  transition: 0.5ms ease-in-out;
  border: none;
}

.total {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
  /* width: 350px; */
  font-weight: 500;
}

.scroll {
  max-height: 10%;
  overflow-y: scroll;
  overflow-x: hidden;
}
/* 
@media (max-width: 1080px) {
  .total {
    width: 210%;
  }
  .cart-container button{
    width: 175%;
  }
}

@media (min-width: 1200px) {
  .total {
    width: 210%;
  }
  .cart-container button{
    width: 225%;
  }
}

@media (max-width: 535px) {
  .total {
    width: 200%;
  }

  .cart-container button {
    width: 180%;
  }
}

@media (min-width: 1300px) {
  .total {
    width: 210%;
  }
  .cart-container button{
    width: 240%;
  }
}
@media (min-width: 1400px) {
  .total {
    width: 210%;
  }
  .cart-container button{
    width: 255%;
  }
} */