
  
  thead {
    background-color: #52e1ba;
  }
  
  .cart-summary thead th {
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0.02em;
    color: #000000;
    line-height:40px;
  }
  
  
  .cart-summary td {
    width: 3%;
    height: 66.25px;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 50px;
    color: #000000;
  }
  
  td button{
    margin-top: 5%;
  }
  
  td span{
    margin-top: 5%;
  }
  
  .category {
    background: #d9d9d9;
  }
  
  .category h1 {
    font-family: "Poppins";
    font-size: 20px;
    letter-spacing: 0.02em;
    /* Text/Primary */
    color: #1a1a1a;
    margin-left: 3%;
    margin-top: 1.25%;
    font-weight: bold;
  }
  
  .qty-btn {
    background-color: #f2f6f7;
    line-height: 50%;
    color: #1a1a1a;
    border: none;
  }
  .qty-text {
    background-color: #f2f6f7;
    color: #1d9909;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.02em;
    line-height: 25px;
  }
  
  .add-btn {
    width: 75%;
    height: 30px;
    background: #0A9A73;
    border-radius: 5px;
    font-family: "Poppins";
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #ffffff;
    line-height: 50%;
    font-weight: 600;
    border: none;
    margin-top: 9%;
  }
  
  .buttons{
    margin-top: 9%;
  }

  @media print {
    /* Hide unnecessary elements for printing, like buttons and headers */
    button {
      display: none;
    }
  
    /* Customize styles for printing, like font size, margin, etc. */
    h1 {
      font-size: 24px;
      margin: 0;
    }
  }
  
  
  
  
  
  
  
  