.brand-header {
  font-family: "Poppins";
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  
}


nav {
  background-color: #0A9A73;
}
.link-active {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #1a1a1a;
}

.link {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.link:active {
  color: #ffffff;
  font-weight: bold;
}


.login-button {
  width: 87px;
  height: 35px;
  background: #0a9a73;
  border: 2pt solid #ffffff;
  border-radius: 24px;
  cursor: pointer;
  color: white;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-align: center;
}

.login-button:hover{
  background: #ffffff;
  color: #0A9A73;
  transition-duration: 5ms;
  transition: ease-in-out;
}



.logout-button {
  width: 87px;
  height: 35px;
  background: #0a9a73;
  border: 2pt solid #ffffff;
  border-radius: 24px;
  cursor: pointer;
  color: white;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-align: center;
}

.logout-button:hover{
  background: #ffffff;
  color: #0A9A73;
  transition-duration: 2ms;
  transition: all ease-in-out;
}

.navbar{
  margin-left: auto;
  padding-left: 7%;
  
}

.container{
  min-height: 6vh;
}

@media(max-width: 300px){
  .navbar{
    padding-left: 40%;
    padding-top: 10%;
  }

  .container{
    min-height: 4vh;
  }
}

@media(max-width: 400px){
  .navbar{
    padding-left: 40%;
    padding-top: 10%;
  }

  .container{
    min-height: 4vh;
  }
}


@media(max-width: 500px){
  .navbar{
    padding-left: 40%;
    padding-top: 10%;
  }

  .container{
    min-height: 1.5vh;
  }
}

/* @media(min-width: 500px){
  .navbar{
    padding-left: 40%;
    padding-top: 10%;
  }

  .container{
    min-height: 7%;
  }
} */







