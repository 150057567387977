.label {
  font-family: 'Poppins';
  font-weight: 500;
  margin-left: 6%;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #1a1a1a;
}

.add_button {
  background-color: transparent;
  border-color: #0A9A73;
  color: #0A9A73;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.02em;
}


.add_button:hover {
  background: #005F45;
  transition: 0.5ms ease-in-out;
  border: none;
}
.add_button p:hover {
  color: white;
  transition: 0.5ms ease-in-out;
  border: none;
}

.search {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  background: #ffffff;
}

.search-icon {
  margin-left: -10px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #c9c7c7ef;
  border-radius: 5px;
}

option {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #000000;
}

::placeholder{
  font-size: 14px;
}