
.breadcrum {
  font-family: "Gilroy-Regular";
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #b3b3b3;
  margin-top: 0.5%;
}

.breadcrum .dot {
  width: 7px;
  height: 7px;
  background: #3bb54a;
  border-radius: 100%;
  margin-top: 1%;
}

.breadcrum .active{
    color: #0094FF;
}

input{
  height: 45px;
}

.submit-button{
  background: #0A9A73;
  border-radius: 8px;
  color: white;
  border-color: transparent;
  font-family: "Poppins";
  font-weight: 500;
  height: 48px;
}

.submit-button:hover{
  background: #005F45;
  transition: 0.5ms ease-in-out;
  border: none;
}


