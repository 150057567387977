/* .table-scroll{
  max-height:70%;
} */


/* Show the scroll bar when enabled */

table thead{
  background-color:#E7E7E7;
}

.Category td{
  background-color: #F2F6F7;
}


thead th {
  font-family: Poppins;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #000000;
  line-height:40px;
}


td {
  font-family: "Poppins";
  line-height: 50px;
  color: #000000;
}

td button{
  margin-top: 5%;
}

td span{
  margin-top: 5%;
}



@media(max-width: 500px){
  

  .order-table tbody tr:nth-child(even){
    background-color: #f5f5f5;
    
  }

  .order-table thead{
    display: none;
  }

  .order-table, .order-table tbody, .order-table tr, .order-table td{
		display: block;
		width: 100%;
    
    
    
	}

  .order-table .action-buttons{
    margin-left: 72%;
    
  }

	.order-table tr{
		margin-bottom:10%;
    border: 1px solid;
    border-top: 2px solid;
    border-bottom: 2px solid;
	}
	.order-table td{
		text-align: right;
		padding-left: 50%;
		padding-right: 10px;
		position: relative;
    height: 50px;
    border-bottom: 0.25px solid #dce1e6;
    
    
	}
	.order-table td::before{
		content: attr(data-label);
		position: absolute;
		left:0;
		width: 50%;
		padding-left:15px;
		font-size:15px;
		font-weight: bold;
		text-align: left;
	}

 
}



.Category {
  background: #d9d9d9;
}

.Category h1 {
  font-family: "Poppins";
  letter-spacing: 0.02em;
  /* Text/Primary */
  color: #1a1a1a;
  margin-left: 3%;
  margin-top: 1.25%;
  font-weight: 600;
}

.qty-btn {
  background-color: #F2F6F7;
  line-height: 50%;
  color: #1a1a1a;
  border: none;
}
.qty-text {
  background-color: #f2f6f7;
  color: #1d9909;
  font-family: "Poppins";
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 25px;
}

.add-btn {
  background: #0A9A73;
  border-radius: 5px;
  font-family: "Poppins";
  letter-spacing: 0.02em;
  color: #ffffff;
  line-height: 50%;
  font-weight: 600;
  border: none;
  margin-top: 9%;
  
}

.add-btn:hover{
  color: white;
  background: #005F45;
  transition: 0.5ms ease-in-out;
  border: none;
}

.buttons{
  margin-top: 9%;
}

::placeholder{
  font-size: 14px;
}

::-webkit-scrollbar {
  width: 0.3em;
  height: 0.5em;
}
::-webkit-scrollbar-thumb {
  background-color:#0a9a74e7;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999; /* Ensure the sticky bar is above other content */
  /* Add any additional styles for the sticky search bar here */
  
}

.search{
  height: 50px;
  margin-bottom: 1.5%;
  width: 100%;
}

ul li{
  margin-bottom: 10px;
}

.sticky-search-bar {
  position: sticky;
  top: 0; /* Stick to the top of the viewport */
}









