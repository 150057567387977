.background {
  position: absolute;
  width: 100%;
  background: rgba(26, 26, 26, 0.8);
  height: 100%;
  overflow: hidden;
}

.Container {
  height: max-content;
  font-family: "Poppins";
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 30px;
}

.Container h3 {
  font-family: "Poppins";
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: black;
  font-weight: bold;
  padding-top: 6%;

}

.line {
  width: 20%;
  height: 1.2%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6%;
  color: #0A9A73;
}

.input {
  margin-left: 18%;
  margin-top: 10%;
  margin-bottom: 3%;
}

.input_box {
  width: 80%;
  height: 35%;
  border: 1px solid #666666;
  border-radius: 8px;
}

.input button {
  margin-top: 13%;
  background-color: #0A9A73;
  color: white;
  border: none;
  width: 80%;
  margin-bottom: 1%;
}

.containr {
  position: relative;
  height: 100%;
}

.centeredDiv {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  width: 100%;
  /* Additional styles as needed */
}

.column-head {
  margin-top: 5%;
  color: var(--light-primary, #0A9A73);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.46px;
}
.column-sub-head{
  color:black;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.46px;
}

/* @media(max-width: 500px) and (max-height: 900px) {
  .Container{
    margin-top: 40%;
  }
}

@media(max-width: 500px) and (max-height: 800px) {
  .Container{
    margin-top: 30%;
  }
}

@media(max-width: 500px) and(max-height: 700px) {
  .Container{
    margin-top: 20%;
  }
}

@media(max-width: 500px) and(max-height: 650px) {
  .Container{
    margin-top: 10%;
  }
}

@media(max-width: 600px) and(max-height: 900px) {
  .Container{
    margin-top: 10%;
  }
}

@media(min-width: 1000px) and (max-height: 700px) {
  .Container{
    margin-top: 3%;
  }
}

@media(min-width: 1000px) and (min-height: 700px) {
  .Container{
    margin-top: 9%;
  }
}

@media(max-width: 1200px) and (max-height: 800px) {
  .Container{
    margin-top: 10%;
  }
} */

.button{
  border: 1px solid #0A9A73;
  border-radius: 4px;
  color: #0A9A73;
  font-weight: 500;
  height: 41px;
}

.button:hover{
  background-color: #157347;
  color: white;
  transition: ease-out;
  transition-duration: 5ms;
}

.passcodes input{
  border: 1px solid rgba(0, 0, 0, 0.23); 
  font-weight: 900; 
  font-size: "25px";
}

.dashed-line {
  border: none; /* Remove default border */
  border-top: 2px dashed black; /* Set dashed border on top side */
  width: 100%; /* Full width */
}




